import { computed } from "vue"
// store
import { useUserStore } from "@/store"
// service
import { setUserHomePage } from "../src/api"

const useSettleHomepage = () => {
  const userStore = useUserStore()
  const homePage = computed(() => window.menuIdItemMap[userStore.userSettings?.homepageMenuId]?.url ?? "")
  // 由于 Homepage 存入后端, 故开启频率限制
  let timer = null
  const handleHomePageAssign = (event, menuItem) => {
    event?.stopPropagation?.()
    event?.preventDefault?.()
    if (timer) return
    timer = setTimeout(() => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }, 750)
    if (homePage.value === menuItem.url) {
      setUserHomePage([{ menuId: menuItem.menuId || window.menuUrlItemMap[menuItem.url].menuId, isHomepage: false }])
      userStore.SET_USERSETTINGS({ ...(userStore.userSettings || {}), homepageMenuId: null })
      window.HomePageId = -1
      window.HomePage = ""
    } else {
      setUserHomePage([
        { menuId: menuItem.menuId || window.menuUrlItemMap[menuItem.url].menuId, isHomepage: true },
        ...(homePage.value ? [{ menuId: window.menuUrlItemMap[homePage.value].menuId, isHomepage: false }] : [])
      ])
      userStore.SET_USERSETTINGS({ ...(userStore.userSettings || {}), homepageMenuId: menuItem.menuId })
      window.HomePageId = menuItem.menuId
      window.HomePage = menuItem.url
    }
  }

  return {
    homePage,
    handleHomePageAssign
  }
}

export { useSettleHomepage }
