import { app, InitApp } from "./frame"
// store & storage
import { useAppStore, useUserStore, useCommonStore } from "@/store"
import { keepCurrentPersistedData } from "../store/cache-restore.js"
// vendor
import { navigationGuard as oktaNavGuard } from "@okta/okta-vue"
import { analyticsIdentify, useAnalyticsTrack } from "./analytics/index.js"
import { loadAppcues } from "./appcues"
// utils
import { pacvueToken } from "@pacvue/utils"
import { decodeBasicUserInfoByToken } from "../utils/auth.js"
import { pollLocale, pollAndCacheLocaleNew } from "../utils/locale.js"
import { logAndTrackLoginDuration, writeLoginInfoAndNavigate } from "../utils/login.js"
import { logoutProcessing } from "../utils/logout.js"
import { handleAuthCodeAndReloadPage, logAndTrackNavDuration } from "../utils/nav.js"
import { productLineHandler } from "../utils/special-logic.js"
import { getPltAnnouncement } from "../utils/common"
import { shouldReload } from "./timeout"
// config
import config from "@~/config.js"
// constants
import { DOMAIN_MAPPER_PROD } from "../constants/platform.js"
import { isCN } from "../constants/env.js"

const shortcutNames = ["login", "SSO Platform Select", "SSO Backward Redirect", "OktaProfile", "Auth", "ActiveEmail"] // 捷径路由名称
const loadingKeptNames = ["OktaCallback", "OktaCallbackIntermediate"] // 始终保持全局加载的路由

function endRequest() {
  window.$httpArray.forEach((item) => {
    item()
  })
  window.$httpArray = []
}

export default function (router) {
  // router.beforeEach(oktaNavGuard)

  router.beforeEach(async (to, from, next) => {
    console.log(`from: ${from.path} navigate to: ${to.path}`)
    const _this = this || {}
    let productLine = window.productline || localStorage.getItem("productline")
    productLine = productLineHandler({ commonStoreContext: useCommonStore(), productLine })
    const isNewLocaleAsync = config.isNewLocaleAsync || (productLine === "walmart" && isCN) || productLine === "retailer"

    const nextFn = async (nextParam, { shouldPollLocale = true } = {}) => {
      // 放行则默认需要在国际化监听加载完毕上阻塞
      const localePollEnabled = shouldPollLocale && !nextParam
      if (isNewLocaleAsync && localePollEnabled) {
        // 国际化轮询及缓存
        await pollAndCacheLocaleNew()
      }
      if (nextParam) {
        window.inNextGuardLoops = true // 进入下一趟钩子, 有些缓存强度较弱的业务接口无需再重复调用
        next(nextParam)
      } else {
        next()
      }
    }

    // okta 认证回调后的调用逻辑在 Callback 组件中处理 (以下两个 route name 都是对应 Callback 组件, 只是路由 params 不同)
    if (["OktaCallback", "OktaCallbackIntermediate"].includes(to.name)) {
      await nextFn()
      return
    }

    // 特殊平台的跳转路径预处理拦截
    if (InitApp.pathPreprocessor) {
      const isDifferentPath = await InitApp.pathPreprocessor(to, from, nextFn)
      if (isDifferentPath) return
    }

    if (to.query.authcode) {
      await handleAuthCodeAndReloadPage(from, to)
      next()
      return
    }

    // 内部站点跳转逻辑处理
    if (to.query.crossNav || (from.path === "/" && !window.perDataKept)) {
      await keepCurrentPersistedData()
      if (to.query.crossNav) {
        window.isCrossNav = true
      }
      // 登录跨域跳转而来 (GroupM, Itsumo), 需要向当前域写入登录标志
      if (to.query.crossDomainLogin) {
        localStorage.setItem("SignFlag", true)
      }
      // 保证此 if block 不会再次进入
      window.perDataKept = true
      const destPath = to.query.redirect || to.path
      const extraParams = to.query.payload ? { query: { query: to.query.payload } } : {}
      // 通过路由过程中的 query 信息告知后续 userInfo 及菜单请求不能使用缓存
      if (to.query.noCache) {
        if (!extraParams.query) {
          extraParams.query = {}
        }
        extraParams.query.noCache = "1"
      }
      // 不开放登录页的平台: HQ, 乐天, Itsumo 还需要记录跳转而来的源平台
      if (config.noLogin && to.query.crossNav && to.query.query) {
        localStorage.setItem("navSrcPlt", to.query.query)
        if (!to.query.redirect) {
          // 没有明确重定向的，才需要去往第一个有权限的页面
          localStorage.setItem("SignFlag", true)
        }
      }
      // 是否从 Itsumo 跳转而来 (不再能通过 to.query.query 判断, 转而根据 referrer 即源平台 origin 判断)
      if (to.query?.srcHost?.includes("itsumo")) {
        window.spClient = "Itsumo"
      }
      // 直接进入下一钩子，并顺带清除 path query
      next(to.query.crossNav ? { path: destPath, ...extraParams } : { path: to.path, query: to.query })
      return
    }

    // 假如有前置的清除操作
    if (_this.clearingFn && typeof _this.clearingFn === "function") {
      _this.clearingFn()
    }

    // 新国际化 CDN 方案、非新异步策略
    if (!isNewLocaleAsync && !["commerce-walmart"].includes(productLine)) {
      await pollLocale()
    }

    if (to.fullPath.lastIndexOf("/login") !== -1 || shortcutNames.includes(to.name)) {
      document.getElementById("pacvue-loading").style.display = "none"
      await nextFn()
      return
    }

    const userStore = useUserStore()
    const appStore = useAppStore()
    appStore.SET_activePath(to.path)

    try {
      if (!to.query.shareId && !to.meta?.__is_share__) {
        if (!pacvueToken.getPacvue_token()) {
          await logoutProcessing()
          next()
          return
        }
        // 率先解码出 token 中的 userId、ClientId
        decodeBasicUserInfoByToken({ userStoreContext: userStore })
        let phase = 0
        let currPhaseInterceptors = []
        while ((currPhaseInterceptors = InitApp.allRouterInterceptors[`phase${phase++}`] || []).length > 0) {
          const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(to, from, nextFn)))
          await Promise.all(promises)
        }
        // 偏好语言与当前环境不同, 则需要重刷页面, 拿对应语言最新的语言包
        if (window.prefLangDiffered) {
          if (from.path.includes("login") || from.path === "/Auth") {
            const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
            window.location.replace(`/${pathPrefix}${to.fullPath}`.replace("//", "/"))
          } else {
            window.location.reload()
          }
          nextFn(undefined, { localePollEnabled: false })
          return
        }
      } else {
        // Share Link 相关路由拦截器
        let phase = 0
        let currPhaseInterceptors = []
        while ((currPhaseInterceptors = InitApp.shareLinkRouterInterceptors[`phase${phase++}`] || []).length > 0) {
          const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(to, from, nextFn)))
          await Promise.all(promises)
        }
      }
    } catch (err) {
      console.error(err)
      next("/login")
    }
    app.config.globalProperties.$eventHub.emit("routerBeforeHook", { to, from, nextFn })

    // 通用无权限判定, Itsumo 不校验平台权限
    const pltNoPermission = !window.pseudoProductLine && userStore.user?.availablePlatforms?.length > 0 && !userStore.user?.availablePlatforms?.includes(productLine)
    const moduleNoPermission = to.meta?.alias && window.NoPermissionPage?.[to.meta.alias]
    if (!_this.hasPermissionProxy && (pltNoPermission || moduleNoPermission) && to.name !== "NoPermission") {
      next({ name: "NoPermission" })
    }

    /* 登录最终步骤 Start */
    if ((from.path.includes("login") || from.path === "/Auth" || from.path === "/") && localStorage.getItem("SignFlag") == "true") {
      localStorage.setItem("SignFlag", false)
      analyticsIdentify().then(() => {
        useAnalyticsTrack("Login Success", {
          category: "Login",
          "login type": "username",
          "Client Select": clientSelect
        })
      })
      const clientSelect = localStorage.getItem("isMultiClient") ? "Select Client" : "No need to select"
      if (userStore.user && !userStore.user.isEmailValidated && userStore.userSettings && !userStore.userSettings.mailValidatePop) {
        localStorage.setItem("EmailVerifyPrompt", true)
      }
      localStorage.removeItem("isMultiClient")
      let autoLogoutPath
      if (from.query.Rurl) {
        autoLogoutPath = isNaN(from.query.Rurl) ? from.query.Rurl : localStorage.getItem(`pacvue_logout_path_${from.query.Rurl}`)
      }
      // 若路由的某次钩子进行到最后一步仍是去往欢迎页, 则直接放行
      if (to.path === "/Guide") {
        await nextFn()
        endRequest()
      } else {
        const homePage = window.HomePage && window.menuUrlItemMap?.[window.HomePage]?.permissionType > 0 ? window.HomePage : ""
        nextFn(autoLogoutPath || homePage || window.firstRouter)
        return
      }
    }
    /* 登录最终步骤 End */

    await nextFn()
    endRequest()
  })

  router.afterEach((to, from, next) => {
    window.inNextGuardLoops = false
    window.spClient = null
    const userInfo = useUserStore().user
    // 路由改造前, 向 Segment 记录登录总时长，分为当前产品线直接登录 与 跨产品线登录，以及跨平台跳转总时长
    logAndTrackLoginDuration({ userInfo, to })
    logAndTrackNavDuration({ userInfo, to })
    if (!loadingKeptNames.includes(to.name)) {
      document.getElementById("pacvue-loading").style.display = "none"
    }
    if (!config.noAppcues && !window.Appcues && window.AppcuesSettings && !to.path.includes("login") && !shortcutNames.includes(to.name)) {
      loadAppcues()
    }
    if (!config.noGlobalAnnouncement && to.path in (window.menuUrlItemMap || {})) {
      getPltAnnouncement(userInfo)
    }
    app.config.globalProperties.$eventHub.emit("routerAfterEachHook", { to, from, next })
  })

  router.onError((error, to) => {
    const productLine = window.productline || localStorage.getItem("productline")
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      if (shouldReload()) {
        // 存储当前时间戳
        sessionStorage.setItem("lastReloadTime", new Date().getTime())
        // 刷新页面
        const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
        window.location.replace(`/${pathPrefix}${to.fullPath}`.replace("//", "/"))
      }
    }
  })
}
