const SENDER = {
  MODEL: "NLP_GPT",
  SELF: "Human"
}

const CONVERSATION_TYPE = {
  FEATURE_INSTRUCTION: "Feature Instruction",
  DATA_QUERY: "Data Query",
  INSIGHT: "Insight",
  NOTIFICATION: "Notification"
}

const DISPLAY_MODE = {
  FLOAT: "float",
  FIXED_RIGHT: "fixed_right"
}

const STATUS_CODE = {
  NOT_FOUND: 404,
  ERROR: 500,
  INSIGHT_FORBIDDEN: 4031,
  QUOTA_EXCEEDS: 4032,
  DATA_QUERY_FORBIDDEN: 5001
}

const FRESHMAN_GUIDANCE_ACTION = {
  ACCEPT: 1,
  REJECT: 2
}

const INTENTION_TYPE = {
  DATA_QUERY: 1,
  NAVIGATION: 3,
  FEATURE_INSTRUCTION: 4,
  INSIGHT: 5
}

// 对话末尾强制命令
const CONV_COMPULSORY_COMMAND = {
  EN: ", please use English to answer the question.",
  ZH: "，请使用中文回答问题。",
  JA: "、質問に回答するには日本語を使用してください。"
}

export { SENDER, CONVERSATION_TYPE, DISPLAY_MODE, STATUS_CODE, FRESHMAN_GUIDANCE_ACTION, INTENTION_TYPE, CONV_COMPULSORY_COMMAND }
