import { app } from "../src/frame"
import { useCommonStore, useUserStore } from "@/store"
import { pacvueToken } from "../src/frame"
import { DOMAIN_MAPPER_PROD, DOMAIN_MAPPER_TEST } from "../constants/platform"
import { isDev, isWhiteLabel } from "../constants/env"
import config from "@~/config.js"
import { useAnalyticsReset } from "../src/analytics"
import { productLineHandler } from "./special-logic"
import { rawLocalRemover } from "../store/storage-enhancer"

const logoutClearStorageAndConn = async () => {
  const productLine = localStorage.getItem("productline")
  if (window.pullSocket) {
    window.pullSocket.close()
    window.pullSocket = null
  }
  if (window.commerceWebSocket) {
    window.commerceWebSocket.close(1000, "client close")
  }
  let keyArray = []
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    const regExp =
      /(_tWidth|_per_store_|pacvue_logout_path|locale|productline|recentLoginQueue|LastSearch|__lastest-plan|Advertising_isActionExpand|_announceMsgId|storage_|oldtipsClick|customOktaState)/
    if (!regExp.test(key)) {
      keyArray.push(key)
    }
  }
  keyArray.forEach((key) => {
    // 需要使用原生 remove 方法来清除 key
    rawLocalRemover.call(localStorage, key)
  })
  sessionStorage.clear()
  pacvueToken.removePacvue_token()
  localStorage.removeItem("productline")
  return productLine
}

const logoutNavigate = ({ productLine, srcProductLine, Rurl } = {}) => {
  const params = new URLSearchParams()
  const commonStore = useCommonStore()
  if (Rurl) {
    params.append("Rurl", Rurl)
  }
  if (!isWhiteLabel) {
    params.append("recentLoginQueue", localStorage.getItem("recentLoginQueue") || "")
  }
  // HQ、Rakuten SOV 的 401 触发跳转登录页逻辑特殊, 需要跳转回源平台登录页，故额外发送总线事件
  if (["retailer", "rakuten"].includes(productLine) || ["Itsumo"].includes(window.pseudoProductLine)) {
    app.config.globalProperties.$eventHub.emit("responseUnauthorizedHook", srcProductLine)
  } else {
    const DOMAIN_MAPPER = isDev ? DOMAIN_MAPPER_TEST : DOMAIN_MAPPER_PROD
    // bol 特殊处理
    productLine = productLineHandler({ commonStoreContext: commonStore, productLine })
    const pathPrefix = DOMAIN_MAPPER[productLine]?.pathPrefix ?? ""
    let loginUrlPath = `/${pathPrefix}/login?${params.toString()}`
    loginUrlPath = loginUrlPath.replace("//", "/")
    window.location.href = loginUrlPath
  }
}

const logoutProcessing = async (extraArgs = {}) => {
  const commonStore = useCommonStore()
  const userStore = useUserStore()
  // 重置埋点
  if (config.analytics) {
    await useAnalyticsReset()
    delete window.__analyticsIdentify__
  }
  // HQ、Rakuten SOV 保留源平台字段
  const srcProductLine = localStorage.getItem("navSrcPlt")
  // 业务重置、清除
  if (commonStore.logout) {
    await commonStore.logout()
  }
  userStore.logout && userStore.logout()
  // 清除 storage 数据
  const productLine = await logoutClearStorageAndConn()
  // 跳转登录页 (跨域跳转前复用上方逻辑, 但不执行下方重定向登录页)
  if (!extraArgs.noNavigate) {
    logoutNavigate({ productLine, srcProductLine, ...extraArgs })
  }
}

export { logoutProcessing }
